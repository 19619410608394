/** @jsx h */
/** @jsxFrag Fragment */
import { h, Fragment } from "preact";
import { useEffect, useState, useContext } from "preact/hooks";
//
// form
import { FieldApi, FormApi, useField, useForm, ValidationError } from "react-form";
import { zodValidator } from "zod-val";
import { Pipeline } from "~/src/frontend/guitMonit.tsx";
import { TunaEffects, AllTunaEffects } from "~/libs/tuna/tsunaTypes.tsx";
import { ctxApp } from "~/src/frontend/index.tsx";

export interface NewEffect {
  name: string;
  effectType: TunaEffects;
}

export const EmptyEffect: NewEffect = {
  name: "untitled",
  effectType: "Chorus",
};

export function FormUpdate(props: {}) {
  const { pipeline } = useContext(ctxApp);
  const [addState, setAddState] = useState<NewEffect>(EmptyEffect);
  const [initValues, setInitValues] = useState(pipeline.getValues());

  const form = useForm({
    defaultValues: initValues,
    onSubmit: (s) => {},
  });

  useEffect(() => {
    pipeline.onChange(() => {
      form.update(pipeline.getValues() as any);
    });
  }, [pipeline]);



  const effects = pipeline.pipe.map((p, i) => {
    const effect = pipeline.effects[p];

    const controls = Object.entries(effect.params).map(([name, audioProp], idx) => {
      return (
        //@ts-ignore
        <form.Field name={`effects[${p}].params.[${name}].value`}>
          {(field) => {
            let ctrl;
            switch (audioProp.type) {
              case "boolean":
                ctrl = <></>;
                break;
              case "string":
                ctrl = <></>;
                break;
              case "int":
                ctrl = (
                  <div class={`ctrl-slider`}>
                    <div class={`header`}>
                      <div class={`name`}>{audioProp.label || name}</div>
                      <div class={`value`}>{audioProp.value}</div>
                    </div>
                    <input
                      type="range"
                      min={audioProp.min}
                      max={audioProp.max}
                      step={1}
                      value={field.state.value as number}
                      onChange={(e: any) => {
                        field.handleChange((e.target as any).value);
                      }}
                    />
                  </div>
                );
              case "float":
                ctrl = (
                  <>
                    <div class={`header`}>
                      <div class={`name`}>{audioProp.label || name}</div>
                      <div class={`value`}>{audioProp.value}</div>
                    </div>
                    <input
                      type="range"
                      min={audioProp.min}
                      max={audioProp.max}
                      step={audioProp.step || 0.001}
                      value={audioProp.value}
                      onChange={(e: any) => {
                        const fValue = parseFloat((e.target as any).value);
                        pipeline.nodeSetParams({
                          id: effect.id,
                          param: name,
                          value: fValue,
                        });

                        field.handleChange(fValue);
                      }}
                    />
                  </>
                );
            }

            return <div class={`effects-param`}>{ctrl}</div>;
          }}
        </form.Field>
      );
    });

    const effectForm = (
      <fieldset class={`pipeline-effect`}>
        <legend class="pipeline-effect-header">
          <input type="checkbox" checked={effect.params["bypass"].value || false} />
          <label></label>
          {effect.id}: {effect.nodeType}
        </legend>
        <div class={`pipeline-params`}>{controls}</div>
      </fieldset>
    );

    return effectForm;
  });

  return (
    <div class={`effects-pipeline-wrapper`}>
      <fieldset class={`effects-pipeline`}>
        <legend>Effects Pipeline</legend>

        <fieldset>
          <legend>Add</legend>
          <div class="field-row">
            <label>Add:</label>
            <select
              value={addState.effectType}
              onChange={(e) => {
                //@ts-ignore
                setAddState({ ...addState, effectType: e.target.value });
              }}
            >
              {AllTunaEffects.map((e) => (
                <option value={e}>{e}</option>
              ))}
            </select>
            <input type="text" value={addState.name} />
            <button
              onClick={() => {
                pipeline.addNode({ nodeType: [addState.effectType], id: addState.name });
              }}
            >
              Add
            </button>
          </div>
        </fieldset>
        <div class={`effects-pipeline-list`}>{effects}</div>
      </fieldset>
    </div>
  );
}
