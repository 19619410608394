export function notNull(o: any) {
  return o != null;
}

export function NoOp(...args: any[]) {}

export function uniq(v: any, i: number, s: any) {
  return s.indexOf(v) === i;
}

export function clone<T = any>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function groupBy<T, K extends keyof any>(key: (i: T) => K, arr: T[]) {
  return arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
}

export type mapFn<V, K, R> = (value: V, key: K, index?: number) => R;
export function mapObjIndexed<V, K extends string, R>(fn: mapFn<V, K, R>, obj: Record<K, V>) {
  const arr = Object.entries(obj) as [K, V][];
  const mapped = arr.map(([k, v], i) => [k, fn(v, k, i)]);
  return Object.fromEntries(mapped) as Record<K, R>;
}

export function zip(arr: any[], ...arrays: any[]) {
  return arr.map((val, i) => arrays.reduce((a, arr) => [...a, arr[i]], [val]));
}

export function joinEach(a: string[], b: string[], sep: string = "") {
  return a.map((f, i) => f + sep + b[i]);
}

export function arrayHasDuplicates(array: any[]) {
  return new Set(array).size !== array.length;
}

export function chunk<T>(array: T[], chunkSize = 4) {
  const res: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res;
}

export function sum(a: number, b: number) {
  return a + b;
}

export interface sumByProps<T> {
  key: (e: T) => string;
  val: (e: T) => number;
}

export function sumBy<T>(props: sumByProps<T>, arr: T[]) {
  return arr.reduce(
    (a, b: T) => {
      const k = props.key(b);
      const v = props.val(b);
      a[k] = (a[k] || 0) + v;
      return a;
    },
    {} as {
      [key: string]: number;
    }
  );
}

export function arrayMove(arr: any[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing
}

export function prefixObjectKeys(obj: any, prefix: string) {
  const rr = Object.entries(obj).map(([k, v]) => [`${prefix}${k}`, v]);
  return Object.fromEntries(rr);
}

export function intOrDefault(x: any, defaultVal: number) {
  try {
    const res = parseInt(x, 10);
    if (Number.isInteger(res)) return res;
    return defaultVal;
  } catch (e) {
    return defaultVal;
  }
}

export function rngSeed(seed: number) {
  let s = seed;
  return () => {
    var t = (s += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export function pick<T>(rng: () => number = Math.random, arr: T[]) {
  return arr[Math.floor(rng() * arr.length)];
}

export function between(rng: () => number = Math.random, min: number, max: number) {
  return Math.floor(rng() * (max - min + 1) + min);
}

export const objToCSVString = (obj: any[], sep = "|") => {
  const header = Object.keys(obj[0]);
  const body = obj
    .map((r) => {
      return Object.values(r).join(sep);
    })
    .join("\n");

  return header.join(sep) + "\n" + body;
};

export function pad(n: any) {
  return (v: any) => `${v}`.padStart(n, "0");
}

const pad2 = pad(2);
const pad3 = pad(3);

export const printLogTS = (ts: number) => {
  const d = new Date(ts);
  return [
    pad2(d.getHours()),
    pad2(d.getMinutes()),
    pad2(d.getSeconds()),
    pad3(d.getMilliseconds()),
  ].join(":");
};

export const CLog = (msg: any) => {
  console.log(printLogTS(Date.now()), msg);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
