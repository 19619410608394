export const FLOAT = "float";
export const BOOLEAN = "boolean";
export const STRING = "string";
export const INT = "int";

export type BOOLEAN = "boolean";
export type INT = "int";
export type FLOAT = "float";
export type STRING = "string";
export type AudioPropType = BOOLEAN | INT | FLOAT | STRING;

export interface AudioPropBase {
  type: AudioPropType;
  value: any;
  automatable: boolean;
  label?: string;
  idx?: number;
}

export interface AudioPropNumeric extends AudioPropBase {
  type: INT | FLOAT;
  value: number;
  min?: number;
  max?: number;
  scaled?: boolean;
}

export interface AudioPropInt extends AudioPropNumeric {
  type: INT;
}

export interface AudioPropFloat extends AudioPropNumeric {
  type: FLOAT;
  step?: number;
}

export interface AudioPropString extends AudioPropBase {
  type: STRING;
  value: string;
}

export interface AudioPropBool extends AudioPropBase {
  type: BOOLEAN;
  value: boolean;
}

export type AudioProp = AudioPropBool | AudioPropInt | AudioPropFloat | AudioPropString;

export interface AudioEffectParam {
  [key: string]: AudioProp;
}

export type AllEffectParams =
  | BitcrusherParams
  | CabinetParams
  | ChorusParams
  | CompressorParams
  | ConvolverParams
  | DelayParams
  | FilterParams
  | GainParams
  | MoogFilterParams
  | OverdriveParams
  | PannerParams
  | PhaserParams
  | PingPongDelayParams
  | TremoloParams
  | WahWahParams
  | EnvelopeFollowerParams
  | LFOParams;

export const AllTunaEffects = [
  "Bitcrusher",
  "Cabinet",
  "Chorus",
  "Compressor",
  "Convolver",
  "Delay",
  "Filter",
  "Gain",
  "MoogFilter",
  "Overdrive",
  "Panner",
  "Phaser",
  "PingPongDelay",
  "Tremolo",
  "WahWah",
  "EnvelopeFollower",
  "LFO",
] as const;

export type TunaEffects = (typeof AllTunaEffects)[any];

// export type TunaEffects =
//   | "Bitcrusher"
//   | "Cabinet"
//   | "Chorus"
//   | "Compressor"
//   | "Convolver"
//   | "Delay"
//   | "Filter"
//   | "Gain"
//   | "MoogFilter"
//   | "Overdrive"
//   | "Panner"
//   | "Phaser"
//   | "PingPongDelay"
//   | "Tremolo"
//   | "WahWah"
//   | "EnvelopeFollower"
//   | "LFO";

export interface BitcrusherParams extends AudioEffectParam {
  bits: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: INT;
  };
  bufferSize: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: INT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  normfreq: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
}
export interface CabinetParams extends AudioEffectParam {
  makeupGain: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface ChorusParams extends AudioEffectParam {
  feedback: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  delay: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  depth: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  rate: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface CompressorParams extends AudioEffectParam {
  threshold: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  release: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  makeupGain: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  attack: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  ratio: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  knee: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  automakeup: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface ConvolverParams extends AudioEffectParam {
  highCut: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  lowCut: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  dryLevel: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  wetLevel: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  level: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface DelayParams extends AudioEffectParam {
  delayTime: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  feedback: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  cutoff: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  wetLevel: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  dryLevel: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface FilterParams extends AudioEffectParam {
  frequency: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  Q: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  gain: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  filterType: {
    value: "lowpass";
    automatable: boolean;
    type: STRING;
  };
}
export interface GainParams extends AudioEffectParam {
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  gain: {
    value: number;
    automatable: boolean;
    type: FLOAT;
  };
}
export interface MoogFilterParams extends AudioEffectParam {
  bufferSize: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: INT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  cutoff: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  resonance: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
}
export interface OverdriveParams extends AudioEffectParam {
  drive: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
    scaled: boolean;
  };
  outputGain: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
    scaled: boolean;
  };
  curveAmount: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  algorithmIndex: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: INT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface PannerParams extends AudioEffectParam {
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  pan: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
}
export interface PhaserParams extends AudioEffectParam {
  rate: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  depth: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  feedback: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  stereoPhase: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  baseModulationFrequency: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface PingPongDelayParams extends AudioEffectParam {
  delayTimeLeft: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: INT;
  };
  delayTimeRight: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: INT;
  };
  feedback: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  wetLevel: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface TremoloParams extends AudioEffectParam {
  intensity: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  stereoPhase: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  rate: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface WahWahParams extends AudioEffectParam {
  automode: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
  baseFrequency: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  excursionOctaves: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  sweep: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  resonance: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  sensitivity: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface EnvelopeFollowerParams extends AudioEffectParam {
  attackTime: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  releaseTime: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}
export interface LFOParams extends AudioEffectParam {
  frequency: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  offset: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  oscillation: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  phase: {
    value: number;
    min: number;
    max: number;
    automatable: boolean;
    type: FLOAT;
  };
  bypass: {
    value: boolean;
    automatable: boolean;
    type: BOOLEAN;
  };
}

export const BitcrusherDefaults: BitcrusherParams = {
  bits: {
    value: 4,
    min: 1,
    max: 16,
    automatable: false,
    type: INT,
  },
  bufferSize: {
    value: 4096,
    min: 256,
    max: 16384,
    automatable: false,
    type: INT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
  normfreq: {
    value: 0.1,
    min: 0.0001,
    max: 1.0,
    automatable: false,
    type: FLOAT,
  },
};
export const CabinetDefaults: CabinetParams = {
  makeupGain: {
    value: 1,
    min: 0,
    max: 20,
    automatable: true,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const ChorusDefaults: ChorusParams = {
  feedback: {
    value: 0.4,
    min: 0,
    max: 0.95,
    automatable: false,
    type: FLOAT,
  },
  delay: {
    value: 0.0045,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  depth: {
    value: 0.7,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  rate: {
    value: 1.5,
    min: 0,
    max: 8,
    automatable: false,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const CompressorDefaults: CompressorParams = {
  threshold: {
    value: -20,
    min: -60,
    max: 0,
    automatable: true,
    type: FLOAT,
  },
  release: {
    value: 250,
    min: 10,
    max: 2000,
    automatable: true,
    type: FLOAT,
  },
  makeupGain: {
    value: 1,
    min: 1,
    max: 100,
    automatable: true,
    type: FLOAT,
  },
  attack: {
    value: 1,
    min: 0,
    max: 1000,
    automatable: true,
    type: FLOAT,
  },
  ratio: {
    value: 4,
    min: 1,
    max: 50,
    automatable: true,
    type: FLOAT,
  },
  knee: {
    value: 5,
    min: 0,
    max: 40,
    automatable: true,
    type: FLOAT,
  },
  automakeup: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const ConvolverDefaults: ConvolverParams = {
  highCut: {
    value: 22050,
    min: 20,
    max: 22050,
    automatable: true,
    type: FLOAT,
  },
  lowCut: {
    value: 20,
    min: 20,
    max: 22050,
    automatable: true,
    type: FLOAT,
  },
  dryLevel: {
    value: 1,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  wetLevel: {
    value: 1,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  level: {
    value: 1,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const DelayDefaults: DelayParams = {
  delayTime: {
    value: 100,
    min: 20,
    max: 1000,
    automatable: false,
    type: FLOAT,
  },
  feedback: {
    value: 0.45,
    min: 0,
    max: 0.9,
    automatable: true,
    type: FLOAT,
  },
  cutoff: {
    value: 20000,
    min: 20,
    max: 20000,
    automatable: true,
    type: FLOAT,
  },
  wetLevel: {
    value: 0.5,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  dryLevel: {
    value: 1,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const FilterDefaults: FilterParams = {
  frequency: {
    value: 800,
    min: 20,
    max: 22050,
    automatable: true,
    type: FLOAT,
  },
  Q: {
    value: 1,
    min: 0.001,
    max: 100,
    automatable: true,
    type: FLOAT,
  },
  gain: {
    value: 0,
    min: -40,
    max: 40,
    automatable: true,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
  filterType: {
    value: "lowpass",
    automatable: false,
    type: STRING,
  },
};
export const GainDefaults: GainParams = {
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
  gain: {
    value: 1.0,
    automatable: true,
    type: FLOAT,
  },
};
export const MoogFilterDefaults: MoogFilterParams = {
  bufferSize: {
    value: 4096,
    min: 256,
    max: 16384,
    automatable: false,
    type: INT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
  cutoff: {
    value: 0.065,
    min: 0.0001,
    max: 1.0,
    automatable: false,
    type: FLOAT,
  },
  resonance: {
    value: 3.5,
    min: 0.0,
    max: 4.0,
    automatable: false,
    type: FLOAT,
  },
};
export const OverdriveDefaults: OverdriveParams = {
  drive: {
    value: 0.197,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
    scaled: true,
  },
  outputGain: {
    value: -9.154,
    min: -46,
    max: 0,
    automatable: true,
    type: FLOAT,
    scaled: true,
  },
  curveAmount: {
    value: 0.979,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  algorithmIndex: {
    value: 0,
    min: 0,
    max: 5,
    automatable: false,
    type: INT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const PannerDefaults: PannerParams = {
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
  pan: {
    value: 0.0,
    min: -1.0,
    max: 1.0,
    automatable: true,
    type: FLOAT,
  },
};
export const PhaserDefaults: PhaserParams = {
  rate: {
    value: 0.1,
    min: 0,
    max: 8,
    automatable: false,
    type: FLOAT,
  },
  depth: {
    value: 0.6,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  feedback: {
    value: 0.7,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  stereoPhase: {
    value: 40,
    min: 0,
    max: 180,
    automatable: false,
    type: FLOAT,
  },
  baseModulationFrequency: {
    value: 700,
    min: 500,
    max: 1500,
    automatable: false,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const PingPongDelayDefaults: PingPongDelayParams = {
  delayTimeLeft: {
    value: 200,
    min: 1,
    max: 10000,
    automatable: false,
    type: INT,
  },
  delayTimeRight: {
    value: 400,
    min: 1,
    max: 10000,
    automatable: false,
    type: INT,
  },
  feedback: {
    value: 0.3,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  wetLevel: {
    value: 0.5,
    min: 0,
    max: 1,
    automatable: true,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const TremoloDefaults: TremoloParams = {
  intensity: {
    value: 0.3,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  stereoPhase: {
    value: 0,
    min: 0,
    max: 180,
    automatable: false,
    type: FLOAT,
  },
  rate: {
    value: 5,
    min: 0.1,
    max: 11,
    automatable: false,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const WahWahDefaults: WahWahParams = {
  automode: {
    value: true,
    automatable: false,
    type: BOOLEAN,
  },
  baseFrequency: {
    value: 0.153,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  excursionOctaves: {
    value: 3.3,
    min: 1,
    max: 6,
    automatable: false,
    type: FLOAT,
  },
  sweep: {
    value: 0.35,
    min: 0,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  resonance: {
    value: 19,
    min: 1,
    max: 100,
    automatable: false,
    type: FLOAT,
  },
  sensitivity: {
    value: -0.5,
    min: -1,
    max: 1,
    automatable: false,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const EnvelopeFollowerDefaults: EnvelopeFollowerParams = {
  attackTime: {
    value: 0.003,
    min: 0,
    max: 0.5,
    automatable: false,
    type: FLOAT,
  },
  releaseTime: {
    value: 0.5,
    min: 0,
    max: 0.5,
    automatable: false,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};
export const LFODefaults: LFOParams = {
  frequency: {
    value: 1,
    min: 0,
    max: 20,
    automatable: false,
    type: FLOAT,
  },
  offset: {
    value: 0.85,
    min: 0,
    max: 22049,
    automatable: false,
    type: FLOAT,
  },
  oscillation: {
    value: 0.3,
    min: -22050,
    max: 22050,
    automatable: false,
    type: FLOAT,
  },
  phase: {
    value: 0,
    min: 0,
    max: 2 * Math.PI,
    automatable: false,
    type: FLOAT,
  },
  bypass: {
    value: false,
    automatable: false,
    type: BOOLEAN,
  },
};

export const EffectDefaults: { [key in TunaEffects]: AudioEffectParam } = {
  Bitcrusher: BitcrusherDefaults,
  Cabinet: CabinetDefaults,
  Chorus: ChorusDefaults,
  Compressor: CompressorDefaults,
  Convolver: ConvolverDefaults,
  Delay: DelayDefaults,
  Filter: FilterDefaults,
  Gain: GainDefaults,
  MoogFilter: MoogFilterDefaults,
  Overdrive: OverdriveDefaults,
  Panner: PannerDefaults,
  Phaser: PhaserDefaults,
  PingPongDelay: PingPongDelayDefaults,
  Tremolo: TremoloDefaults,
  WahWah: WahWahDefaults,
  EnvelopeFollower: EnvelopeFollowerDefaults,
  LFO: LFODefaults,
};
