/// <reference lib="dom"/>

/** @jsx h */
/** @jsxFrag Fragment */
import "preact/debug";
import { h, render, Fragment, createContext } from "preact";

import * as xstate from "xstate";
import { useMachine } from "xstate-react";

import { NoOp } from "~/libs/utils/utils.tsx";
import { AudioContextMeta, InitMonitor } from "~/src/frontend/audioPatch.tsx";
import { Pipeline, PipelineDef } from "~/src/frontend/guitMonit.tsx";

import ELK, { ElkExtendedEdge, ElkNode } from "https://esm.sh/elkjs@0.9.3?target=es2020";
import { useEffect, useRef, useState } from "preact/hooks";
import * as d3 from "https://esm.sh/d3?target=es2020";
import { FormUpdate } from "~/src/frontend/pipeUi.tsx";

import { DebugGraph } from "~/src/frontend/debugGraph.tsx";

export interface AppCtx {
  state: {
    pipeline: PipelineDef;
    error: boolean;
  };
  pipeline: Pipeline;
}

export const ctxApp = createContext<AppCtx>({
  state: {
    pipeline: {} as any,
    error: false,
  },
  pipeline: null as any,
});

export function DebugReload(props: { codePort: number; cssPort: Number }) {
  try {
    new EventSource(`http://localhost:${props.cssPort}/esbuild`).addEventListener("change", () => {
      document
        .querySelectorAll<HTMLLinkElement>("link[rel=stylesheet]")
        .forEach((link) => (link.href = link.href.replace(/\?.*|$/, "?" + Date.now())));
    });
    new EventSource(`http://localhost:${props.codePort}/esbuild`).addEventListener("change", () => {
      location.reload();
    });
  } catch (e) {}
}

const pipeGet = InitMonitor().then((v) => {
  console.log(AudioContextMeta);
  const pipe = new Pipeline(v);
  pipe.addNode({
    id: "test_comp",
    nodeType: ["Compressor"],
  });
  return pipe;
});

const elk = new ELK();

export function DebugView(props: { pipeline: Pipeline }) {
  const [graphData, setGraphData] = useState<null | ElkNode>(null);

  const getLayoutData = async () => {
    const nodePaths = AudioContextMeta.nodes.map((n) => {
      const [pathStr, idx] = n.id.split("-");

      return {
        id: n.id,
        width: 100,
        height: 30,
      };
    });

    const newNodes = AudioContextMeta.nodes.map((n) => {
      return {
        id: n.id,
        width: 100,
        height: 30,
      };
    });

    const newEdges = AudioContextMeta.connections.map((c, i) => {
      if (!c[0].id || !c[1].id) {
        console.log(c[0]);
      }
      return {
        id: `edge-${i}`,
        sources: [c[0].id],
        targets: [c[1].id],
      };
    });

    const gd = {
      id: "root",
      layoutOptions: { "elk.algorithm": "layered", "elk.direction": "DOWN" },
      children: newNodes,
      edges: newEdges,
    };

    console.log(newNodes.length);
    console.log(graphData);

    const res = await elk.layout(gd);
    setGraphData(res);
  };

  useEffect(() => {
    props.pipeline.onChange(() => {
      getLayoutData();
    });
    getLayoutData();
  }, [props.pipeline]);

  if (graphData) {
    return <DebugGraph graphData={graphData} />;
  }

  return <div></div>;
}

export function PipelineViewer(props: {}) {
  return (
    <div>
      <div>
        <FormUpdate />
      </div>
    </div>
  );
}

function MainUI() {
  const [areaState, setAreaState] = useState<AppCtx>({
    state: {
      pipeline: {} as any,
      error: false,
    },
    pipeline: null as any,
  });

  useEffect(() => {
    pipeGet
      .then((p) => {
        setAreaState({ state: { pipeline: p.getValues(), error: false }, pipeline: p });

        p.onChange(() => {
          setAreaState({ state: { pipeline: p.getValues(), error: false }, pipeline: p });
        });
      })
      .catch(() => {
        setAreaState({ state: { pipeline: {} as any, error: true }, pipeline: {} as any });
      });
  }, []);

  if (areaState.state.error)
    return (
      <div>
        <button onClick={() => {
          InitMonitor().then((v) => {
            const pipe = new Pipeline(v);
            pipe.addNode({
              id: "test_comp",
              nodeType: ["Compressor"],
            });
            
            setAreaState({ state: { pipeline: pipe.getValues(), error: false }, pipeline: pipe });

            
          });
        }}>allow mic access</button>
      </div>
    );
  if (!areaState.pipeline) return <></>;

  return (
    <ctxApp.Provider value={areaState}>
      <div>
        <button onClick={() => {
          InitMonitor().then((v) => {
            const pipe = new Pipeline(v);
            pipe.addNode({
              id: "test_comp",
              nodeType: ["Compressor"],
            });
            
            setAreaState({ state: { pipeline: pipe.getValues(), error: false }, pipeline: pipe });

            
          });
        }}>allow mic access</button>
      </div>
      <PipelineViewer />
    </ctxApp.Provider>
  );
}

function main() {
  const el = document.getElementById("main");
  if (el) render(<MainUI />, el);
}

DEV: DebugReload({ codePort: 8766, cssPort: 8765 });
main();
