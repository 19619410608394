export function dbToWAVolume(db: number) {
  return Math.max(0, Math.round(100 * Math.pow(2, db / 6)) / 100);
}

export function fmod(x: number, y: number) {
  // http://kevin.vanzonneveld.net
  // *     example 1: fmod(5.7, 1.3);
  // *     returns 1: 0.5
  var tmp,
    tmp2,
    p = 0,
    pY = 0,
    l = 0.0,
    l2 = 0.0;

  tmp = x.toExponential().match(/^.\.?(.*)e(.+)$/);
  //@ts-ignore
  p = parseInt(tmp[2], 10) - (tmp[1] + "").length;
  tmp = y.toExponential().match(/^.\.?(.*)e(.+)$/);
  //@ts-ignore
  pY = parseInt(tmp[2], 10) - (tmp[1] + "").length;

  if (pY > p) {
    p = pY;
  }

  tmp2 = x % y;

  if (p < -100 || p > 20) {
    // toFixed will give an out of bound error so we fix it like this:
    l = Math.round(Math.log(tmp2) / Math.log(10));
    l2 = Math.pow(10, l);
    //@ts-ignore
    return (tmp2 / l2).toFixed(l - p) * l2;
  } else {
    return parseFloat(tmp2.toFixed(-p));
  }
}

export function sign(x: number) {
  if (x === 0) {
    return 1;
  } else {
    return Math.abs(x) / x;
  }
}

export function tanh(n: number) {
  return (Math.exp(n) - Math.exp(-n)) / (Math.exp(n) + Math.exp(-n));
}

export function initValue(userVal: any, defaultVal: any) {
  return userVal === undefined ? defaultVal : userVal;
}
