/// <reference lib="dom"/>

/** @jsx h */
/** @jsxFrag Fragment */
import "preact/debug";
import { h, render, Fragment, createContext } from "preact";

import * as xstate from "xstate";
import { useMachine } from "xstate-react";

import { NoOp } from "~/libs/utils/utils.tsx";
import { AudioContextMeta, InitMonitor } from "~/src/frontend/audioPatch.tsx";
import { Pipeline } from "~/src/frontend/guitMonit.tsx";

import ELK, { ElkExtendedEdge, ElkNode } from "https://esm.sh/elkjs@0.9.3?target=es2020";
import { useEffect, useRef, useState } from "preact/hooks";
import * as d3 from "https://esm.sh/d3?target=es2020";

export const chart = (el: HTMLElement, initData: ElkNode) => {
  const width = 500;
  const height = 1000;

  var svg = d3
    .select(el)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .call(
      d3.zoom().on("zoom", function (e) {
        svg.attr("transform",e.transform);
      })
    )
    .append("g");

  // define an arrow head
  svg
    .append("svg:defs")
    .append("svg:marker")
    .attr("id", "end")
    .attr("viewBox", "0 -5 10 10")
    .attr("refX", 10)
    .attr("refY", 0)
    .attr("markerWidth", 3) // marker settings
    .attr("markerHeight", 5)
    .attr("orient", "auto")
    .style("fill", "#999")
    .style("stroke-opacity", 0.6) // arrowhead color
    .append("svg:path")
    .attr("d", "M0,-5L10,0L0,5");

  var root = svg.append("g");

  const update = (el: HTMLElement, data: ElkNode) => {
    if (!data) return;
    var nodes = data.children as ElkNode[];
    var links = data.edges as ElkExtendedEdge[];

    //#region nodes #1 add the nodes' groups
    var nodeData = root.selectAll(".node").data(nodes, function (d: ElkNode) {
      return d.id;
    });

    var node = nodeData
      .enter()
      .append("g")
      .attr("class", function (d) {
        if (d.children) return "node compound";
        else return "node leaf";
      });

    // add representing boxes for nodes
    var box = node
      .append("rect")
      .attr("class", "atom")
      .attr("width", (nodeData: ElkNode) => {
        return nodeData.width;
      })
      .attr("height", (nodeData: ElkNode) => {
        return nodeData.height;
      })
      .attr("x", (nodeData: ElkNode) => {
        return nodeData.x;
      })
      .attr("y", (nodeData: ElkNode) => {
        return nodeData.y;
      });
    // add node labels
    node
      .append("text")
      .attr("x", (nodeData: ElkNode) => {
        return nodeData.x + 5;
      })
      .attr("y", (nodeData: ElkNode) => {
        return nodeData.y + 15;
      })
      .text(function (d) {
        return d.id;
      })
      .attr("font-size", "10px");

    //#endregion

    //#region #2 add paths with arrows for the edges
    var linkData = root.selectAll(".link").data(links, function (d) {
      return d.id;
    });

    var link = linkData
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", "M0 0")
      .attr("marker-end", "url(#end)")
      .attr("d", function (d: ElkExtendedEdge) {
        const sect = d.sections[0];
        var path = "";
        if (sect.startPoint && sect.endPoint) {
          path += "M" + sect.startPoint.x + " " + sect.startPoint.y + " ";
          (sect.bendPoints || []).forEach(function (bp, i) {
            path += "L" + bp.x + " " + bp.y + " ";
          });
          path += "L" + sect.endPoint.x + " " + sect.endPoint.y + " ";
        }
        return path;
      });

    //#endregion

    //#endregion

    //#region
    //#endregion

    //#region
    //#endregion
  };

  update(el, initData);

  return update;
};

export function DebugGraph(props: { graphData: ElkNode }) {
  const ref = useRef<HTMLDivElement>(null as any);
  const updateCb = useRef<CallableFunction>();

  useEffect(() => {
    if (ref.current) {
      if (updateCb.current) {
        updateCb.current(ref.current, props.graphData);
      } else {
        updateCb.current = chart(ref.current, props.graphData);
      }
    }
  }, [ref.current, props.graphData]);

  return (
    <div class={`debug-wrapper`}>
      <div class="main-graph-wrapper">
        <div class="d3-wrap" ref={ref}></div>
      </div>
    </div>
  );
}
