import { Tuna } from "~/libs/tuna/tuna.js";

export const AudioContextMeta = {
  nodes: [] as any[],
  connections: [] as any[],
  history: [] as any[],
};

export function TrackCreate(node: any) {
  AudioContextMeta.nodes.push(node);
}
export function TrackDelete(node: any) {
  const idx = AudioContextMeta.nodes.findIndex((n) => n.id === node.id);
  node.disconnect();
  if (idx !== -1) {
    AudioContextMeta.nodes.splice(idx, 1);
  }
}
export function TrackConnect(node: any, dest: any) {
  AudioContextMeta.connections.push([node, dest]);
}
export function TrackDisconnect() {}

export function GetNextNodeId(id?: string) {
  const nodeIds = AudioContextMeta.nodes.map((n) => n.id);
  const PipelineBaseName = id ?? `unknown`;
  let n = 1;
  while (nodeIds.indexOf(`${PipelineBaseName}-${n}`) !== -1) {
    n++;
  }
  return `${PipelineBaseName}-${n}`;
}

export interface createNodeProps {
  name?: string;
  id?: string;
  systemId?: string;
}

declare global {
  export interface AudioNode {
    _connections: (AudioNode | AudioParam)[];
    name: string;
    id: string;
    type: string;
  }

  export interface AudioContext {
    createAnalyser: (props?: createNodeProps) => AnalyserNode;
    createGain: (props?: createNodeProps) => GainNode;
    createDynamicsCompressor: (props?: createNodeProps) => DynamicsCompressorNode;
    //@ts-ignore
    createMediaStreamSource: (
      mediaStream: MediaStream,
      props?: createNodeProps
    ) => MediaStreamAudioSourceNode;
  }
}

//#region context patch

const OrigCtxFns: { [key: string]: any } = {
  createAnalyser: AudioContext.prototype.createAnalyser,
  createBiquadFilter: AudioContext.prototype.createBiquadFilter,
  createBuffer: AudioContext.prototype.createBuffer,
  createBufferSource: AudioContext.prototype.createBufferSource,
  createChannelMerger: AudioContext.prototype.createChannelMerger,
  createChannelSplitter: AudioContext.prototype.createChannelSplitter,
  createConstantSource: AudioContext.prototype.createConstantSource,
  createConvolver: AudioContext.prototype.createConvolver,
  createDelay: AudioContext.prototype.createDelay,
  createDynamicsCompressor: AudioContext.prototype.createDynamicsCompressor,
  createGain: AudioContext.prototype.createGain,
  createIIRFilter: AudioContext.prototype.createIIRFilter,
  createMediaElementSource: AudioContext.prototype.createMediaElementSource,
  createMediaStreamDestination: AudioContext.prototype.createMediaStreamDestination,
  createMediaStreamSource: AudioContext.prototype.createMediaStreamSource,
  createOscillator: AudioContext.prototype.createOscillator,
  createPanner: AudioContext.prototype.createPanner,
  createPeriodicWave: AudioContext.prototype.createPeriodicWave,
  createStereoPanner: AudioContext.prototype.createStereoPanner,
  createWaveShaper: AudioContext.prototype.createWaveShaper,
  createScriptProcessor: AudioContext.prototype.createScriptProcessor,
} as const;

AudioContext.prototype.createAnalyser = function (this, props?: createNodeProps) {
  const node = OrigCtxFns.createAnalyser.apply(this, arguments);
  node.type = "analyser";
  node.id = GetNextNodeId(props?.id ?? node.type);
  node.name = props?.name || "untitled";
  TrackCreate(node);
  return node;
};

AudioContext.prototype.createBiquadFilter = function (this, props?: createNodeProps) {
  const node = OrigCtxFns.createBiquadFilter.apply(this, arguments);
  node.name = props?.name || "untitled";
  node.type = "BiquadFilter";
  node.id = GetNextNodeId(props?.id ?? node.type);
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createBuffer = function (this) {
  const node = OrigCtxFns.createBuffer.apply(this, arguments);
  node.type = "buffer";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createBufferSource = function (this) {
  const node = OrigCtxFns.createBufferSource.apply(this, arguments);
  node.type = "BufferSource";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createChannelMerger = function (this) {
  const node = OrigCtxFns.createChannelMerger.apply(this, arguments);
  node.type = "ChannelMerger";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createChannelSplitter = function (this) {
  const node = OrigCtxFns.createChannelSplitter.apply(this, arguments);
  node.type = "ChannelSplitter";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createConstantSource = function (this) {
  const node = OrigCtxFns.createConstantSource.apply(this, arguments);
  node.type = "ConstantSource";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createConvolver = function (this) {
  const node = OrigCtxFns.createConvolver.apply(this, arguments);
  node.type = "Convolver";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createDelay = function (this) {
  const node = OrigCtxFns.createDelay.apply(this, arguments);
  node.type = "Delay";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createDynamicsCompressor = function (this, props?: createNodeProps) {
  const node = OrigCtxFns.createDynamicsCompressor.apply(this, arguments);
  node.type = "DynamicsCompressor";
  node.id = GetNextNodeId(props?.id ?? node.type);
  node.name = props?.name || "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createGain = function (this, props?: createNodeProps) {
  const node = OrigCtxFns.createGain.apply(this, arguments);
  node.type = "gain";
  node.name = props?.name || "untitled";
  node.id = GetNextNodeId(props?.id ?? node.type);

  TrackCreate(node);

  return node;
};
AudioContext.prototype.createIIRFilter = function (this) {
  const node = OrigCtxFns.createIIRFilter.apply(this, arguments);
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createMediaElementSource = function (this) {
  const node = OrigCtxFns.createMediaElementSource.apply(this, arguments);
  node.type = "MediaElementSource";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createMediaStreamDestination = function (this) {
  const node = OrigCtxFns.createMediaStreamDestination.apply(this, arguments);
  node.type = "MediaStreamDestination";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createMediaStreamSource = function (
  this,
  mediaStream: MediaStream,
  props?: createNodeProps
) {
  const node = OrigCtxFns.createMediaStreamSource.apply(this, arguments);
  node.name = props?.name || "untitled";
  node.type = "MediaStreamSource";
  node.id = GetNextNodeId(props?.id ?? node.type);
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createOscillator = function (this, props?: createNodeProps) {
  const node = OrigCtxFns.createOscillator.apply(this, arguments);
  node.type = "Oscillator";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createPanner = function (this) {
  const node = OrigCtxFns.createPanner.apply(this, arguments);
  node.type = "Panner";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createPeriodicWave = function (this) {
  const node = OrigCtxFns.createPeriodicWave.apply(this, arguments);
  node.type = "PeriodicWave";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createStereoPanner = function (this) {
  const node = OrigCtxFns.createStereoPanner.apply(this, arguments);
  node.type = "stereoPanner";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};
AudioContext.prototype.createWaveShaper = function (this) {
  const node = OrigCtxFns.createWaveShaper.apply(this, arguments);
  node.type = "waveShaper";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};

AudioContext.prototype.createScriptProcessor = function (this) {
  const node = OrigCtxFns.createScriptProcessor.apply(this, arguments);
  node.type = "ScriptProcessor";
  node.id = GetNextNodeId(node.type);
  node.name = "untitled";
  TrackCreate(node);
  return node;
};

//#endregion

const connect = AudioNode.prototype.connect;
const disconnect = AudioNode.prototype.disconnect;
const construct = AudioNode.prototype.constructor;

AudioNode.prototype.constructor = function (this, ...args: any) {
  console.log(args);
  return construct.apply(this, arguments);
};

AudioNode.prototype.connect = function (this, dest) {
  this._connections || (this._connections = []);
  if (this._connections.indexOf(dest) === -1) {
    this._connections.push(dest);
  }
  TrackConnect(this, dest);
  //@ts-ignore
  return connect.apply(this, arguments) as unknown as AudioNode;
};

AudioNode.prototype.disconnect = function (this) {
  //@ts-ignore
  return disconnect.apply(this, arguments);
};

export async function InitMonitor() {
  let ctx;
  try {
    ctx = new AudioContext();
  } catch (e) {
    throw e;
  }

  if(!ctx) {
    throw "no ctx"
  }

  ctx.destination.id = "main.output";
  AudioContextMeta.nodes.push(ctx.destination);

  const stream = await navigator.mediaDevices.getUserMedia({
    audio: {
      echoCancellation: false,
      noiseSuppression: false,
      autoGainControl: false,
    },
  });

  //@ts-ignore
  const node = ctx.createMediaStreamSource(stream, { id: "main.mic" });
  const tuna = new Tuna(ctx);

  return { ctx, stream, node, tuna };
}
