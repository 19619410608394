import { Tuna } from "~/libs/tuna/tuna.js";
import {
  AllEffectParams,
  AudioEffectParam,
  EffectDefaults,
  TunaEffects,
} from "~/libs/tuna/tsunaTypes.tsx";
import { arrayMove, clone, mapObjIndexed } from "~/libs/utils/utils.tsx";

export type EffectId = string;

export interface EffectConfig {
  id: EffectId;
  nodeType: TunaEffects;
  params: AllEffectParams;
}

export type EffectsDict = Record<EffectId, EffectConfig>;
export type EffectsNodeDict = Record<EffectId, any>;

export interface PipelineDef {
  pipe: EffectId[],
  effects: EffectsDict
}

export interface PipelineProps {
  ctx: AudioContext;
  stream: MediaStream;
  node: MediaStreamAudioSourceNode;
  tuna: Tuna;
}

export class Pipeline {
  tuna: Tuna;
  effects = {} as EffectsDict;
  effectNodes = {} as EffectsNodeDict;
  pipe = [] as EffectId[];

  ctorRef: PipelineProps

  io: {
    in: GainNode,
    out: GainNode,
  }

  handlers = [] as CallableFunction[]

  constructor(props: PipelineProps) {
    this.ctorRef = props
    this.tuna = props.tuna;

    this.io = {
      in: props.ctx.createGain({  name: "in gain",  id: `monitor.in`}),
      out: props.ctx.createGain({  name: "out gain",  id: `monitor.out`}),
    }

    props.node.connect(this.io.in)
    this.io.in.connect(this.io.out)
    this.io.out.connect(this.ctorRef.ctx.destination)

  }

  onChange(cb: CallableFunction) {
    this.handlers.push(cb)
  }

  trigger() {
    this.handlers.forEach(cb => cb())
  }

  PipelineGetNextId(id?: string) {
    const PipelineBaseName = id ?? `unamed`;
    let n = 1;
    while (this.pipe.indexOf(`${PipelineBaseName}-${n}`) !== -1) {
      n++;
    }
    return `${PipelineBaseName}-${n}`;
  }

  reconnect() {
    try {
      for (const n of this.io.in._connections) {
        //@ts-ignore
        if (n.type !== "analyser") this.io.in.disconnect(n);
      }

      this.io.out.disconnect(this.io.in);
    } catch (e) {}

    for (const eff of Object.values(this.effects)) {
      this.effectNodes[eff.id].disconnect();
    }
    let lastPipe = this.io.in;
    for (const eId of this.pipe) {
      const n = this.effectNodes[this.effects[eId].id];
      lastPipe.connect(n);
      lastPipe = n;
    }
    lastPipe.connect(this.io.out);
  }

  addNode(props: { nodeType: TunaEffects[]; id?: EffectId; initParams?: AllEffectParams }) {
    const { nodeType, initParams } = props;
    for (const nt of nodeType) {
      const nodeId =  this.PipelineGetNextId(props.id);
      const params: AudioEffectParam =
        props.initParams ?? (clone(EffectDefaults[nt]) as AudioEffectParam);

      //@ts-ignore
      const node = new this.tuna[nt]({ bypass: false });

      this.effects[nodeId] = {
        id: nodeId,
        nodeType: nt,
        params: params as any,
      };
      this.effectNodes[nodeId] = node;
      this.pipe.push(nodeId);
    }
    this.reconnect();
    this.trigger()
  }
  moveNode(props: { from: number; to: number }) {
    this.pipe = arrayMove(this.pipe, props.from, props.to);
    this.reconnect();
    this.trigger()
  }
  rmNode(idx: number) {
    const id = this.effects[this.pipe[idx]].id;
    delete this.effects[id];
    this.pipe.splice(idx, 1);
    this.trigger()
  }
  nodeSetParams(props: { id: string; param: string; value: any }) {
    const { id, param, value } = props;
    this.effectNodes[id][param] = value;
    this.effects[id].params[param].value = value;
    this.reconnect();
    this.trigger()
  }

  getValues(): PipelineDef {
    const effectsExprot = mapObjIndexed(
      (e) => ({ id: e.id, nodeType: e.nodeType, params: e.params }),
      this.effects
    );

    const payload = {
      effects: effectsExprot,
      pipe: this.pipe,
    };

    return payload;
  }

  toString() {
    const effectsExprot = mapObjIndexed(
      (e) => ({ id: e.id, nodeType: e.nodeType, params: e.params }),
      this.effects
    );

    const payload = {
      effects: effectsExprot,
      pipe: this.pipe,
    };

    return JSON.stringify(payload, null, 2);
  }
  // import(props: { export: EffectPipelineExport }) {
  //   const exportData = props.export;

  //   for (const eff of Object.values(this.effects)) {
  //     eff.node.disconnect();
  //   }
  //   for (const key in this.effects) {
  //     if (Object.prototype.hasOwnProperty.call(this.effects, key)) {
  //       delete this.effects[key];
  //     }
  //   }

  //   for (const key of exportData.pipe) {
  //     const conf = exportData.effects[key];
  //     this.addNode({
  //       id: key,
  //       nodeType: [conf.nodeType],
  //       initParams: conf.params,
  //     });
  //   }
  //   this.reconnect();
  // }
}
